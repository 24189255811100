import { config } from '@/config';
import { useEffect } from 'react';
import OneSignal from 'react-onesignal';
import { Outlet } from 'react-router-dom';

export const PublicLayout = () => {
  useEffect(() => {
    runOneSignal();
  }, []);

  const runOneSignal = async () => {
    await OneSignal.init({
      appId: config.ONE_SIGNAL_APP_ID,
      allowLocalhostAsSecureOrigin: true,
    });

    OneSignal.Slidedown.promptPush();
  };

  return (
    <>
      <Outlet />
    </>
  );
};
