import {
  Button,
  Credenza,
  CredenzaBody,
  CredenzaClose,
  CredenzaContent,
  CredenzaFooter,
  CredenzaHeader,
  CredenzaTitle,
  CredenzaTrigger,
  Label,
} from '@/components/common';
import { Form, RHFTextArea } from '@/components/form';
import { cn } from '@/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { IconMessageReport } from '@tabler/icons-react';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import StarRating from './StarRating';
import { useTranslation } from 'react-i18next';
import { useFeedbackCompany } from '@/services/companyService/hooks/useFeedbackCompany';
import { FeedbackREQ } from '@/models/request/feedback.request';

interface FeedbackModalProps {
  isCollapsible?: boolean;
}

export default function FeedbackModal({ isCollapsible }: FeedbackModalProps) {
  const { t: tFeedback } = useTranslation('feedback');
  const { t: tMessage } = useTranslation('message');

  const [open, setOpen] = useState<boolean>(false);
  const [rating, setRating] = useState<number>(3);
  const { feedbackCompany, isLoading: isLoadingSendFeedback } = useFeedbackCompany();

  const formSchema = z.object({
    content: z.string().min(1, tMessage('formError.required')),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      content: '',
    },
  });

  const renderModalTrigger = useMemo(() => {
    if (isCollapsible) {
      return (
        <div className="px-2 w-full cursor-pointer">
          <div className=" w-full justify-start items-center h-8 mb-1 group flex px-3 hover:bg-black dark:hover:bg-white rounded-md">
            <span className={'mr-2 group-hover:text-white dark:group-hover:text-black'}>
              <IconMessageReport size={20} />
            </span>
            <p
              className={cn(
                'max-w-[200px] truncate text-xs font-semibold group-hover:text-white dark:group-hover:text-black',
              )}
            >
              Góp ý
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="px-2">
        <Button variant={'icon'}>
          <IconMessageReport size={20} />
        </Button>
      </div>
    );
  }, [isCollapsible]);

  const handleSubmit = useCallback(
    async (values: z.infer<typeof formSchema>) => {
      const dataSubmit: FeedbackREQ = {
        content: values.content,
        rating: rating,
      };

      await feedbackCompany(dataSubmit);

      form.reset();
      setOpen((prev) => !prev);
    },
    [rating, feedbackCompany, form],
  );

  return (
    <Credenza open={open} onOpenChange={setOpen}>
      <CredenzaTrigger asChild>{renderModalTrigger}</CredenzaTrigger>
      <CredenzaContent>
        <CredenzaHeader>
          <CredenzaTitle>{tFeedback('form.title')}</CredenzaTitle>
        </CredenzaHeader>
        <CredenzaBody>
          <div>
            <Form {...form}>
              <form className="grid gap-5 items-start">
                <RHFTextArea
                  name="content"
                  title={tFeedback('form.label.content')}
                  placeholder={tFeedback('form.placeholder.content')}
                  required
                />
                <div className="space-y-2 flex flex-col items-start">
                  <Label>{tFeedback('form.label.rating')}</Label>
                  <StarRating rating={rating} onRatingChange={setRating} />
                </div>
              </form>
            </Form>
          </div>
        </CredenzaBody>
        <CredenzaFooter>
          <CredenzaClose className="w-full md:w-fit">
            <Button variant={'secondary'} className="w-full">
              {tFeedback('form.button.cancel')}
            </Button>
          </CredenzaClose>
          <Button variant={'success'} onClick={form.handleSubmit(handleSubmit)} loading={isLoadingSendFeedback}>
            {tFeedback('form.button.send')}
          </Button>
        </CredenzaFooter>
      </CredenzaContent>
    </Credenza>
  );
}
