export enum LOCALE_ENUM {
  VI = 'vi',
  EN = 'en',
}

export enum CALL_API_STATUS_ENUM {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum DAY_ENUM {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum GENDER_ENUM {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum USER_STATUS_ENUM {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  BLOCKED = 'BLOCKED',
}

export enum USER_TYPE_ENUM {
  SYSTEM = 'SYSTEM',
  COMPANY = 'COMPANY',
  CANDIDATE = 'CANDIDATE',
}

export enum LINK_TYPE_ENUM {
  FACE_BOOK = 'FACE_BOOK',
  GIT = 'GIT',
  YOUTUBE = 'YOUTUBE',
  TWITTER = 'TWITTER',
  LINKEDIN = 'LINKEDIN',
  WEBSITE = 'WEBSITE',
  OTHER = 'OTHER',
}

export enum PHONE_TYPE_ENUM {
  HOME = 'HOME',
  WORK = 'WORK',
  MOBILE = 'MOBILE',
}

export enum LOCATION_TYPE_ENUM {
  ON_SITE = 'ON_SITE',
  HYBRID = 'HYBRID',
  REMOTE = 'REMOTE',
}

export enum EMPLOYMENT_TYPE_ENUM {
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  FREELANCE = 'FREELANCE',
  CONTRACT = 'CONTRACT',
  INTERNSHIP = 'INTERNSHIP',
  SEASONAL = 'SEASONAL',
}

export enum INTERVIEW_METHOD_ENUM {
  ONLINE = 'ONLINE',
  ON_SITE = 'ON_SITE',
}

export enum INTERVIEW_STATUS_ENUM {
  SUITABLE = 'SUITABLE', //Phừ hợp
  PREFERRED = 'PREFERRED', //Yêu thích
  APPLIED = 'APPLIED', //'Ứng tuyển'
  WAITING_CONTACT = 'WAITING_CONTACT', //Chờ liên hệ
  CANDIDATE_REJECT_CONTACT = 'CANDIDATE_REJECT_CONTACT', //UV từ chối liên hệ
  WAITING = 'WAITING', //Đang chờ phỏng vấn
  COMPANY_REJECT = 'COMPANY_REJECT', //NTD hủy
  CANDIDATE_REJECT = 'CANDIDATE_REJECT', //Không đến phỏng vấn
  INTERVIEWED = 'INTERVIEWED', //Đã phỏng vấn
  FOLLOW_UP_INTERVIEW = 'FOLLOW_UP_INTERVIEW', //Phỏng vấn vòng tiếp theo
  FAILED = 'FAILED', //Không đạt sau phỏng vấn
  JOB_OFFER = 'JOB_OFFER', //Gửi đề nghị làm việc
  CANDIDATE_REJECT_OFFER = 'CANDIDATE_REJECT_OFFER', //UV từ chối làm việc
  INTERSHIP = 'INTERSHIP', //Thực tập
  PROBATION = 'PROBATION', //Thử việc
  PERMANENT_EMPLOYEE = 'PERMANENT_EMPLOYEE', //Nhân viên Chính thức
}

export enum PROCESS_STATUS_ENUM {
  STATUS_1 = 'STATUS_1',
  STATUS_2 = 'STATUS_2',
  STATUS_3 = 'STATUS_3',
  STATUS_4 = 'STATUS_4',
  STATUS_5 = 'STATUS_5',
}

export enum WORK_TYPE_ENUM {
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
  SEASONAL = 'SEASONAL',
  EXPERT_CONTRACT = 'EXPERT_CONTRACT',
}


export enum CANDIDATE_CURRICULUM_VITAE_STATUS_ENUM {
  PREFERRED_CANDIDATE = 'PREFERRED_CANDIDATE',
  SUITABLE_CANDIDATE = 'SUITABLE_CANDIDATE',
}

export enum INTERVIEW_TYPE_ENUM {
  ONLINE = 'ONLINE',
  ON_SITE = 'ON_SITE',
}

export enum CV_STATUS_ENUM {
  WAITING_COMFIRM = 'WAITING_COMFIRM',
  ACTIVE = 'ACTIVE',
  REJECT = 'REJECT',
}

export enum JOB_STATUS_ENUM {
  DRAFT = 'DRAFT',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  WAITING_COMFIRM = 'WAITING_COMFIRM',
  ACTIVE = 'ACTIVE',
  REJECT = 'REJECT',
}

export enum BANK_ACCOUNT_STATUS_ENUM {
  NEW='NEW',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REJECTED='REJECTED'
}

export enum BANK_ACCOUNT_TYPE_ENUM {
  COMPANY = 'COMPANY',
  CANDIDATE = 'CANDIDATE',
}

export enum WALLET_CURRENCY_ENUM {
  'VND' = 'VND',
}

export enum HANDLE_STATUS_ENUM {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  REFUSE = 'REFUSE',
}

export enum PAYMENT_METHOD_ENUM {
  QR_CODE = 'QR_CODE',
  TOKENIZATION = 'TOKENIZATION',
  INSTALLMENT = 'INSTALLMENT',
}

export enum PAYMENT_GATEWAY_ENUM {
  VISA = 'VISA',
  QR = 'QR',
  ALEPAY = 'ALEPAY',
  MOMO = 'MOMO',
  VN_PAY = 'VN_PAY',
  APPLE_PAY = 'APPLE_PAY',
}

export enum TRANSACTION_STATUS_ENUM {
  WAITING = 'WAITING',
  SUCCESS = 'SUCCESS',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
}

export enum WALLET_TYPE_ENUM {
  COMPANY = 'COMPANY',
  CANDIDATE = 'CANDIDATE',
  JOB = 'JOB',
}

export enum TRANSACTION_TYPE_ENUM {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
}

export enum WALLET_LANGUAGE_ENUM {
  VI = 'VI',
  EN = 'EN',
}

export enum ENTITY_TYPE_ENUM {
  COMPANY = 'COMPANY',
  CANDIDATE = 'CANDIDATE',
}

export enum OTP_STATUS_ENUM {
  OTP_SENT = 'OTP_SENT',
  OTP_VERIFIED = 'OTP_VERIFIED',
  OTP_EXPIRED = 'OTP_EXPIRED',
}
