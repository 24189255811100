import CoffeeBeanIcon from '@/assets/icons/Coffee.svg';
import {
  Avatar,
  AvatarImage,
  Button,
  Credenza,
  CredenzaClose,
  CredenzaContent,
  CredenzaDescription,
  CredenzaFooter,
  CredenzaHeader,
  CredenzaTitle,
  CredenzaTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components';
import { LOCAL_STORAGE } from '@/constants';
import { THEMES } from '@/models/theme';
import { UserModel } from '@/models/user';
import { RootState } from '@/redux/store';
import { PATH_AUTH } from '@/routes/paths.routes';
import { asyncLocalStorage } from '@/utils';
import { IconCreditCardPay } from '@tabler/icons-react';
import { Laptop, Moon, Sun } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../../theme/theme-provider';
import { CompanyModel } from '@/models/company';

export function UserNav() {
  const { setTheme } = useTheme();
  const navigate = useNavigate();

  const { t: translate } = useTranslation();

  const userInformation = useSelector<RootState, UserModel>((state) => state.userStore.user);
  const userCompanyInformation = useSelector<RootState, CompanyModel>((state) => state.userStore.userCompany);

  const handleLogOut = () => {
    asyncLocalStorage.removeLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN);
    asyncLocalStorage.removeLocalStorage(LOCAL_STORAGE.REFRESH_TOKEN);
    asyncLocalStorage.removeLocalStorage(LOCAL_STORAGE.EXPIRED_TIME);
    asyncLocalStorage.removeLocalStorage(LOCAL_STORAGE.PERMISSIONS);
    navigate(PATH_AUTH.login);
  };

  return (
    <Credenza>
      <>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Avatar className="rounded-full border cursor-pointer">
              <AvatarImage src={userInformation.avatar?.url} alt="@shadcn" />
            </Avatar>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56" align="end" forceMount>
            <DropdownMenuLabel className="font-normal">
              <div className="flex flex-col space-y-1">
                <p className="text-sm font-medium leading-none">{`${userInformation.lastName} ${userInformation.firstName}`}</p>
                <p className="text-xs leading-none text-muted-foreground">{userInformation.email}</p>
              </div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem className="font-normal">
              <div className="grid space-y-1  w-full">
                <p className="text-sm font-medium leading-none">
                  {translate('systems.elements.navigation.elements.userToggle.elements.accountBalanceDropdownTitle')}
                </p>
                <p className="text-sm leading-none text-muted-foreground flex items-center justify-between gap-5  w-full">
                  <span className="flex gap-2 items-center justify-center font-black text-black ">
                    {userCompanyInformation?.walletDTO?.balance} <SVG src={CoffeeBeanIcon} width={20} height="20" />
                  </span>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <IconCreditCardPay color="green" />
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Nạp tiền</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </p>
              </div>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuItem>
                {translate('systems.elements.navigation.elements.userToggle.elements.personalizationDropdownTitle')}
                <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
              </DropdownMenuItem>

              <DropdownMenuSub>
                <DropdownMenuSubTrigger>
                  {/* <SunIcon className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
              <MoonIcon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" /> */}
                  <span>
                    {translate('systems.elements.navigation.elements.userToggle.elements.themeDropdownTitle')}
                  </span>
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent>
                    <DropdownMenuItem onClick={() => setTheme(THEMES.LIGHT)}>
                      <Sun className="mr-2 h-4 w-4" />
                      {translate('systems.elements.navigation.elements.userToggle.elements.lightModeDropdownTitle')}
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => setTheme(THEMES.DARK)}>
                      <Moon className="mr-2 h-4 w-4" />
                      {translate('systems.elements.navigation.elements.userToggle.elements.darkModeDropdownTitle')}
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={() => setTheme(THEMES.SYSTEM)}>
                      <Laptop className="mr-2 h-4 w-4" />
                      {translate('systems.elements.navigation.elements.userToggle.elements.systemModeDropdownTitle')}
                    </DropdownMenuItem>
                    <DropdownMenuItem disabled className="w-36 text-xs">
                      {translate(
                        'systems.elements.navigation.elements.userToggle.elements.systemModeDropdownDescription',
                      )}
                    </DropdownMenuItem>
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
            </DropdownMenuGroup>
            <DropdownMenuSeparator />

            <CredenzaTrigger asChild>
              <DropdownMenuItem>
                {translate('systems.elements.navigation.elements.userToggle.elements.logoutDropdownTitle')}
                {/* <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut> */}
              </DropdownMenuItem>
            </CredenzaTrigger>
          </DropdownMenuContent>
        </DropdownMenu>

        <CredenzaContent>
          <CredenzaHeader>
            <CredenzaTitle>Bạn có chắc chắn muốn đăng xuất</CredenzaTitle>
            <CredenzaDescription>Thao tác này sẽ làm bạn đăng xuất khỏi hệ thống</CredenzaDescription>
          </CredenzaHeader>

          <CredenzaFooter>
            <Button type="button" variant="danger" onClick={handleLogOut}>
              Đăng xuất
            </Button>
            <CredenzaClose asChild>
              <Button>Hủy</Button>
            </CredenzaClose>
          </CredenzaFooter>
        </CredenzaContent>
      </>
    </Credenza>
  );
}
