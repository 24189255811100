import { CompanyProfileModel } from '@/models/company';
import { COMPANY_PROFILES_URL, COMPANY_URL } from '../apiUrl';
import axiosService from '../axiosService';
import { FeedbackREQ } from '@/models/request/feedback.request';

const CompanyService = {
  updateCompanyProfileByCompanyID: async (id: string, data: FormData): Promise<CompanyProfileModel> => {
    return axiosService()({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `${COMPANY_PROFILES_URL}/${id}`,
      method: 'PATCH',
      data,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
  getCompanyProfileByCompanyID: async (id: string): Promise<CompanyProfileModel> => {
    return axiosService()({
      url: `${COMPANY_PROFILES_URL}/${id}`,
      method: 'GET',
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
  updateCompany: async (id: string, data: FormData): Promise<CompanyProfileModel> => {
    return axiosService()({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `${COMPANY_URL}/${id}`,
      method: 'PATCH',
      data,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
  feedbackCompany: async (data: FeedbackREQ) => {
    return axiosService()({
      headers: {
        'Content-Type': 'application/json',
      },
      url: `${COMPANY_URL}/feedback`,
      method: 'POST',
      data,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default CompanyService;
